<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-dialog v-model="treeDialog" fullscreen>
                <v-card>
                  <v-toolbar
                      dark
                      color="primary"
                  >
                    <v-btn
                        icon
                        dark
                        @click="treeDialog = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span>Property Types</span>
                    </v-toolbar-title>
                  </v-toolbar>

                  <v-card-text>
                    <template>
                      <v-treeview :items="treeFilter"></v-treeview>
                    </template>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog persistent v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn
                        color="#00004d"
                        dark
                        class="ml-auto ma-3"
                        @click="refreshData"
                    >
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                    <v-btn
                        v-if="hasPermission('property_types.create')"
                        color="#00004d" dark class="ma-3 ml-0" v-on="on">
                      Add
                      <v-icon class="ml-2" small>fas fa-plus-circle</v-icon>
                    </v-btn>
                    <v-btn v-if="items.length > 0"
                           @click="showTreeDialog"
                           color="warning" dark class="ma-3 ml-0">
                      Show Tree View
                      <v-icon class="ml-2" small>fas fa-tree</v-icon>
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <i class="fas fa-times custom-times" @click="closeDialog"></i>
                  <v-card-title>
                    <span v-if="editedItem.id">Edit {{ editedItem.title }}</span>
                    <span v-else>Create Property Type</span>
                  </v-card-title>
                  <v-card-text>
                    <v-form
                        ref="form"
                        v-model="validationRules.valid"
                        lazy-validation
                    >
                      <v-row>
<!--                        <v-col cols="12" sm="12">-->
<!--                          <v-autocomplete-->
<!--                              clearable-->
<!--                              outlined-->
<!--                              dense-->
<!--                              :items="typesFilter"-->
<!--                              v-model="editedItem.type"-->
<!--                              :rules="validationRules.selectRules"-->
<!--                              item-text="title"-->
<!--                              item-value="title"-->
<!--                              label="Type*"-->
<!--                              chips-->
<!--                              small-chips-->
<!--                              deletable-chips-->
<!--                          ></v-autocomplete>-->
<!--                        </v-col>-->
                        <v-col cols="12" sm="12">
                          <v-text-field v-model="editedItem.title"
                                        clearable
                                        outlined
                                        dense
                                        :counter="100" maxLength="100"
                                        :rules="validationRules.titleRules"
                                        label="Title*" required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <v-autocomplete
                              clearable
                              outlined
                              dense
                              :items="parentFilter"
                              v-model="editedItem.parent_id"
                              item-text="title"
                              item-value="id"
                              label="Parent"
                              chips
                              small-chips
                              deletable-chips
                          >
                            <!--                            <template slot="selection" slot-scope="{ item }">-->
                            <!--                              <v-chip-->
                            <!--                                  small-->
                            <!--                                  :input-value="item.selected"-->
                            <!--                              >-->
                            <!--                                {{item.title + ' (' + item.type + ')'}}-->
                            <!--                              </v-chip>-->
                            <!--                            </template>-->
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content
                                    v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content>
                                  <v-list-item-title
                                      v-html="data.item.hk + ' (' + data.item.type + ')'"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                    <v-btn :disabled="!validationRules.valid" color="blue darken-1" text
                           @click="saveItem(editedItem)">
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div v-if="errors!=undefined && errors.length >= 0"
                         role="alert"
                         v-bind:class="{ show: errors.length }"
                         class="alert fade alert-danger"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <hr style="margin:0"/>
              <div class="col-md-12">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="custom-expansion-panel-header" color="#00004d">
                      <div class="text-center font-weight-bold">
                        Search/Filters
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-form ref="searchForm" v-on:submit.prevent="hitAdvanceSearch(false)">
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="searchFormData.titleSearch"
                                clearable
                                outlined
                                maxLength="100"
                                dense
                                hide-details
                                label="Title"
                            >
                            </v-text-field>
                          </v-col>
<!--                          <v-col cols="12" sm="6">-->
<!--                            <v-select-->
<!--                                :items="typesFilter"-->
<!--                                v-model="searchFormData.typeSearch"-->
<!--                                clearable-->
<!--                                outlined-->
<!--                                item-text="title"-->
<!--                                item-value="title"-->
<!--                                dense-->
<!--                                hide-details-->
<!--                                label="Type"-->
<!--                            >-->
<!--                            </v-select>-->
<!--                          </v-col>-->
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <button :disabled="loading" type="submit" style="width: 100%"
                                    class="btn btn-success theme-btn ml-auto mr-2">
                              Search
                              <v-icon class="ml-2" small>fas fa-search</v-icon>
                            </button>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <button :disabled="loading" style="width: 100%"
                                    @click="hitAdvanceSearch(true)"
                                    class="btn btn-primary ml-auto">
                              Reset
                              <v-icon class="ml-2" small>fas fa-times</v-icon>
                            </button>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              <v-card-title>
                Property Types ({{ addTSBVZ(totalRecords) }})
<!--                <v-spacer></v-spacer>-->
<!--                <v-text-field-->
<!--                    @keydown.enter="searchQueryData"-->
<!--                    append-icon="search"-->
<!--                    label="Search"-->
<!--                    single-line-->
<!--                    hide-details-->
<!--                ></v-text-field>-->
              </v-card-title>
              <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table"
              >
              </v-skeleton-loader>
              <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="pagination"
                  :server-items-length="totalRecords"
                  :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
                  :loading="loading"
                  v-show="!isLoaded"
                  mobile-breakpoint="100"
                  class="elevation-0">
                <template v-slot:item.title="{ item }">
                  <div class="text-truncate">
                    <p v-html="item.hk"></p>
                  </div>
                </template>
                <template v-slot:item.created_at="{ item }">
                  <div class="text-truncate">
                    {{ formatDateAndTime(item.created_at) }} by
                    {{ item.created_user_name }}
                  </div>
                </template>
                <template v-slot:item.updated_at="{ item }">
                  <div class="text-truncate">
                    {{ formatDateAndTime(item.updated_at) }} by
                    {{ item.updated_user_name }}
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="text-truncate">
                    <v-btn v-if="hasPermission('property_types.update')" color="#00004d"
                           @click="showEditDialog(item)"
                           dark class="mr-2">
                      Edit
                      <v-icon dark right small>fas fa-edit</v-icon>
                    </v-btn>
                    <v-btn @click="deleteItem(item)" color="warning" dark
                           v-if="hasPermission('property_types.archive')" class="mr-2">
                      Archive
                      <v-icon dark right small>fas fa-archive</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
  DEVELOPMENTTYPES_LIST,
  CREATE_DEVELOPMENTTYPES,
  UPDATE_DEVELOPMENTTYPES,
  CLEAR_DEVELOPMENTTYPES_ERRORS,
  DELETE_DEVELOPMENTTYPES, GET_DEVELOPMENTTYPES_DETAILS,
  DEVELOPMENTTYPES_SIMPLE_LIST, DEVELOPMENTTYPES_TREE_LIST
} from "@/core/services/store/developmenttypes.module";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";

export default {
  name: 'property-types',
  data() {
    return {
      headers: [
        {text: 'Title', value: 'title', name: 'title', filterable: true, sortable: true},
        // {text: 'Country', value: 'country.title', name: 'country.title', filterable: false, sortable: false},
        // {text: 'City', value: 'city.title', name: 'city.title', filterable: false, sortable: false},
        // {text: 'Type', value: 'type', name: 'type', filterable: false, sortable: false},
        {
          text: "Created",
          align: ' d-none',
          value: "created_at",
          name: "created_at",
          filterable: false,
          sortable: true
        },
        // {text: "Updated", value: "updated_at", name: "updated_at", filterable: false, sortable: false},
        {text: 'Actions', value: 'actions', name: 'actions', filterable: false, sortable: false},
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      parentFilter: [],
      validationRules: {
        valid: true,
        titleRules: [
          v => !!v || "Title is required",
          v => (v && v.length >= 3) || "Title must be greater than 3 characters",
          v => (v && v.length <= 100) || "Title must be less than 100 characters"
        ],
        selectRules: [
          v => !!v || "This field is required"
        ],
      },
      items: [],
      dialog: false,
      rDialog: false,
      treeDialog: false,
      treeFilter: [],
      editedItem: {},
      searchFormData: {}
    }
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_DEVELOPMENTTYPES_ERRORS);
      if (!val) this.editedItem = {};
      if (!val) this.getTypes();
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].value +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Setup", route: "property-types"},
      {title: "Property Types"}
    ]);
    this.getTypes();
    if (this.$route.query.hasOwnProperty('addModule')) {
      this.dialog = true;
    }
  },
  methods: {
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        this.getTypes();
      });
    },
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query += '&' + searchFormDataQuery
        query +=
            "&is_property=1" +
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 1 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store.dispatch(DEVELOPMENTTYPES_LIST, {q: query, columns: this.columns}).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(item) {
      this.$store.dispatch(GET_DEVELOPMENTTYPES_DETAILS, item.id).then((data) => {
        this.editedItem = data.records || {};
        const index = this.parentFilter.findIndex(element => {
          if (element.id === this.editedItem.id) {
            return true;
          }
        });
        this.parentFilter.splice(index, 1);
        this.dialog = !this.dialog;
      });
    },
    closeDialog() {
      this.dialog = !this.dialog;
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        let method = CREATE_DEVELOPMENTTYPES;
        let id = item.id;
        let data = {
          data: item
        };
        this.$set(item, "country_id", this.$store.state.auth.user.country_id);
        this.$set(item, "city_id", this.$store.state.auth.user.city_id);
        this.$set(item, "is_property", 1);
        if (id) {
          method = UPDATE_DEVELOPMENTTYPES;
          // delete data.data.id;
        }
        this.$store.dispatch(CLEAR_DEVELOPMENTTYPES_ERRORS);
        this.$store.dispatch(method, {'slug': id, data: data.data}).then(() => {
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          this.editedItem = {}
          this.dialog = !this.dialog
          if (this.$route.query.hasOwnProperty('addModule')) {
            window.close();
          }
        })
      }
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?",
        msg: "You want to delete '" + item.title + "'?",
        clr: 'red',
        callback: function () {
          let id = item.id;
          this.$store.dispatch(DELETE_DEVELOPMENTTYPES, {
            'id': id
          }).then(() => {
            that.loadData().then(response => {
              that.items = response.items;
              that.totalRecords = response.total;
            });
          });
        }
      });
    },
    getTypes() {
      this.$store.dispatch(DEVELOPMENTTYPES_SIMPLE_LIST, {type: 'all', is_property: 1}).then(data => {
        this.parentFilter = data.records.developmentTypesList;
      });
    },
    showTreeDialog() {
      this.$store.dispatch(DEVELOPMENTTYPES_TREE_LIST).then((data) => {
        this.treeFilter = data.records.ptList;
        this.treeDialog = true;
      });
    },
    hitAdvanceSearch(reset = false) {
      this.pagination.page = 1
      if (reset) {
        this.searchFormData = {};
      }
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.developmenttypes.errors
    })
  }
};
</script>